import { Button, Table } from "antd";

import "./OrderPart.css";
import { forwardRef } from "react";
import useDisplay from "../../../../hooks/useDisplay";
import useCompany from "../../../../hooks/useCompany";
import useContact from "../../../../hooks/useContact";
import axios_instance from "../../../../requests/api";
import useOrder from "../../../../hooks/useOrder";

interface OrderPartProps {}

const OrderPart = forwardRef<HTMLDivElement, OrderPartProps>((props, ref) => {
  const { order, setOrderCreated } = useOrder();
  const { displayStep } = useDisplay();
  const { company } = useCompany();
  const { contact } = useContact();
  const { setDisplayStep } = useDisplay();
  const dataSource = [
    {
      key: "1",
      requisite: "Отправка",
      company: "АЗК",
      user: "Иван Иванов",
      phone_number: "+7 917 000 00 00",
    },
    {
      key: "2",
      requisite: "Доставка",
      company: `${company?.name}`,
      user: `${contact?.last_name} - ${contact?.first_name}`,
      phone_number: `${contact?.phone_number}`,
    },
  ];

  const columns = [
    {
      title: "Реквизиты доставки",
      dataIndex: "requisite",
      key: "requisite",
    },
    {
      title: "Компания",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Контактное лицо",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Мобильный для связи",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  async function createDelivery() {
    await axios_instance
      .post(`/delivery_plus/delivery/${order?.id}`)
      .then((response: any) => {
        // @ts-ignore
        setOrderCreated((prevState: any) => ({
          ...prevState,
          delivered: true,
        }));
        setDisplayStep(1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (displayStep < 5) return null;

  return (
    <div className="part-main" ref={ref}>
      <div className="part-content-info">
        <span className="circle-block">5</span>
        <span className="part-header-text">Информация доставки</span>
      </div>
      <div className="part-content-main">
        <div className="order-content">
          <Table
            style={{ width: "100%" }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </div>
        <Button
          style={{
            width: "100%",
          }}
          color="danger"
          variant="solid"
          onClick={() => createDelivery()}
        >
          Оформить доставку
        </Button>
      </div>
    </div>
  );
});

export default OrderPart;
