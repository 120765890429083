import { Button, Empty } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import "./OrderCreatedPart.css";
import useDisplay from "../../../../hooks/useDisplay";
import useOrder from "../../../../hooks/useOrder";
import { useEffect } from "react";
import useProduct from "../../../../hooks/useProduct";

const OrderCreatedPart: React.FC = () => {
  const { setDisplayStep } = useDisplay();
  const { setProduct } = useProduct();
  const { orderCreated, setOrderCreated } = useOrder();
  useEffect(() => {
    console.log(orderCreated);
  }, [orderCreated]);
  return (
    <Empty
      style={{ width: "100%", height: "100%", marginTop: 20 }}
      image={<CheckCircleOutlined style={{ fontSize: 50, color: "#52c41a" }} />}
      imageStyle={{ height: 50 }}
      description={
        <span className="emty-description-text">
          <div className="order-created-main">
            <span style={{ marginBottom: 10 }}>Заказ успешно создан</span>
            <span>Номер заказа: {<strong>{orderCreated?.number}</strong>}</span>
            <span>
              Наименование заказа: {<strong>{orderCreated?.name}</strong>}
            </span>
            <span style={{ marginTop: 10 }}>
              Вы можете просмотреть его в разделе "Проверить доставку по номеру"
            </span>
          </div>
        </span>
      }
    >
      <Button
        style={{ width: "70%" }}
        color="danger"
        variant="solid"
        onClick={() => {
          setOrderCreated(undefined);
          setDisplayStep(0);
          setProduct(undefined);
        }}
      >
        Рассчитать доставку
      </Button>
    </Empty>
  );
};

export default OrderCreatedPart;
