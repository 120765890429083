import React, { createContext, useState, ReactNode, useEffect } from "react";
import {
  OrderCreatedProps,
  OrderProject,
  OrderProjectForTracking,
} from "../types/order";

interface OrderContextType {
  order: OrderProject | undefined;
  setOrder: (order: OrderProject | undefined) => void;
  selectedRowKey: React.Key | undefined;
  setSelectedRowKey: (key: React.Key | undefined) => void;
  ordersForTracking: OrderProjectForTracking[] | undefined;
  setOrdersForTracking: (
    ordersForTracking: OrderProjectForTracking[] | undefined
  ) => void;
  orderForTracking: OrderProjectForTracking | undefined;
  setOrderForTracking: (
    orderForTracking: OrderProjectForTracking | undefined
  ) => void;
  orderCreated: OrderCreatedProps | undefined;
  setOrderCreated: (orderCreated: OrderCreatedProps | undefined) => void;
}

export const OrderContext = createContext<OrderContextType | undefined>(
  undefined
);

const OrderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [order, setOrder] = useState<OrderProject | undefined>();
  const [selectedRowKey, setSelectedRowKey] = useState<React.Key | undefined>(
    undefined
  );
  const [ordersForTracking, setOrdersForTracking] = useState<
    OrderProjectForTracking[] | undefined
  >([]);
  const [orderForTracking, setOrderForTracking] = useState<
    OrderProjectForTracking | undefined
  >(undefined);

  const [orderCreated, setOrderCreated] = useState<
    OrderCreatedProps | undefined
  >(undefined);

  useEffect(() => {
    console.log(order);
  }, [order]);

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        selectedRowKey,
        setSelectedRowKey,
        ordersForTracking,
        setOrdersForTracking,
        orderForTracking,
        setOrderForTracking,
        orderCreated,
        setOrderCreated,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderProvider };
