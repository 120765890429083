import { useEffect, useState } from "react";
import Header from "../../layout/header/Header";
import CalcContainer from "./calcContainer/CalcContainer";

import "./MainPage.css";
import Auth from "./auth/Auth";
import { checkIsAuthenticated } from "../../requests/authorization";
import { DisplayProvider } from "../../context/displayContext";
import { CompanyProvider } from "../../context/companyContext";
import { ContactProvider } from "../../context/contactContext";
import { WarehouseProvider } from "../../context/warehouseContext";
import { ProductProvider } from "../../context/productContext";
import { OrderProvider } from "../../context/orderContext";

const MainPage = () => {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response: any = await checkIsAuthenticated();
      setIsAuth(response);
    };

    fetchData();
  }, []);

  return (
    <div className="main-page-main">
      <Header setIsAuth={setIsAuth} isAuth={isAuth} />
      <div className="main-page-content">
        {isAuth ? (
          <DisplayProvider>
            <CompanyProvider>
              <ContactProvider>
                <WarehouseProvider>
                  <ProductProvider>
                    <OrderProvider>
                      <CalcContainer />
                    </OrderProvider>
                  </ProductProvider>
                </WarehouseProvider>
              </ContactProvider>
            </CompanyProvider>
          </DisplayProvider>
        ) : (
          <Auth setIsAuth={setIsAuth} />
        )}
      </div>
    </div>
  );
};

export default MainPage;
