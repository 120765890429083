import { AutoComplete, Button, Empty, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./Tracking.css";
import { useEffect, useState } from "react";
import axios_instance from "../../../../requests/api";
import useOrder from "../../../../hooks/useOrder";
import { OrderProjectForTracking } from "../../../../types/order";

const Tracking = () => {
  const [query, setQuery] = useState("");
  const {
    orderForTracking,
    setOrderForTracking,
    ordersForTracking,
    setOrdersForTracking,
  } = useOrder();

  async function getOrderProjects() {
    await axios_instance
      .get(`/orders/order_project/for-user?query=${query}`)
      .then((response: any) => {
        console.log(response.data);
        setOrdersForTracking(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getOrderProjects();
  }, [query]);

  const handleTrackingChange = (value: any) => {
    const order: any = ordersForTracking?.find((order) => order.id === value);
    setOrderForTracking(order);
  };

  useEffect(() => {
    console.log(orderForTracking);
  }, [orderForTracking]);

  const getStatus = (order: OrderProjectForTracking) => {
    if (order.complited) {
      return {
        status: "Выполнен",
        date: order.complited.create_date_time_stamp,
      };
    } else if (order.delivered) {
      return {
        status: "Доставляется",
        date: order.delivered.create_date_time_stamp,
      };
    } else if (order.payment) {
      return {
        status: "Оплачен",
        date: order.payment.create_date_time_stamp,
      };
    } else if (order.formed_status) {
      return {
        status: "Сформирован",
        date: order.formed_status.create_date_time_stamp,
      };
    }
    return { status: "Неизвестно", date: "" };
  };

  return (
    <div className="part-main">
      {!orderForTracking ? (
        <Empty
          style={{ width: "100%" }}
          imageStyle={{ height: 50 }}
          image={
            <SearchOutlined style={{ fontSize: 50, color: "#00000020" }} />
          }
          description={
            <span className="emty-description-text">
              Введите идентификатор доставки
            </span>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              width: "100%",
            }}
          >
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="Поиск по номеру или наименованию заказа"
              optionFilterProp="label"
              onSearch={(value) => setQuery(value)}
              onChange={handleTrackingChange}
              notFoundContent="Не найдено такой доставки"
              filterOption={() => true}
            >
              {ordersForTracking?.map((order_project) => (
                <Select.Option key={order_project.id} value={order_project.id}>
                  <span style={{ overflowX: "scroll" }}>
                    Номер: <strong>{order_project.number}</strong>,
                    Наименование: <strong>{order_project.name}</strong>
                  </span>
                </Select.Option>
              ))}
            </Select>
          </div>
        </Empty>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <span className="part-header-text">Статус:</span>
              <span>
                {orderForTracking
                  ? getStatus(orderForTracking).status
                  : "Неизвестно"}
              </span>
              {orderForTracking && getStatus(orderForTracking).date && (
                <span>
                  {" "}
                  ({new Date(getStatus(orderForTracking).date).toLocaleString()}
                  )
                </span>
              )}
            </div>
            <Button
              color="danger"
              variant="solid"
              onClick={() => {
                setOrderForTracking(undefined);
              }}
            >
              Проверить другую доставку
            </Button>
          </div>
          <div className="part-content-cell">
            <div className="cell">
              <span style={{ opacity: "50%" }}>Наименование заказа</span>
              <AutoComplete
                disabled
                value={orderForTracking.name}
                style={{ width: "100%" }}
              />
            </div>
            <div className="cell">
              <span style={{ opacity: "50%" }}>Номер доставки</span>
              <AutoComplete
                disabled
                value={orderForTracking.number}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tracking;
