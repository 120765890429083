import React, { createContext, useState, ReactNode } from "react";
import { Contact } from "../types/contact";

interface ContactContextType {
  contact: Contact | undefined;
  setContact: (company: Contact | undefined) => void;
  contacts: Contact[] | undefined;
  setContacts: (contacts: Contact[] | undefined) => void;
  newContact: boolean;
  setNewContact: (newContact: boolean) => void;
}

export const ContactContext = createContext<ContactContextType | undefined>(
  undefined
);

const ContactProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [newContact, setNewContact] = useState(false);
  const [contact, setContact] = useState<Contact | undefined>();
  const [contacts, setContacts] = useState<Contact[] | undefined>([]);

  return (
    <ContactContext.Provider
      value={{
        contact,
        setContact,
        contacts,
        setContacts,
        newContact,
        setNewContact,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export { ContactProvider };
