import React, { createContext, useState, ReactNode, useEffect } from "react";
import {
  ProductForOrderProps,
  ProductSearchProps,
  Property,
} from "../types/product";

interface ProductContextType {
  product: ProductForOrderProps | undefined;
  setProduct: (product: ProductForOrderProps | undefined) => void;
  searchProducts: ProductSearchProps[] | undefined;
  setSearchProducts: (product: ProductSearchProps[] | undefined) => void;
  properties: Property[] | undefined;
  setProperties: (properties: Property[] | undefined) => void;
  newProduct: boolean;
  setNewProduct: (newProduct: boolean) => void;
}

export const ProductContext = createContext<ProductContextType | undefined>(
  undefined
);

const ProductProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [newProduct, setNewProduct] = useState(false);
  const [product, setProduct] = useState<ProductForOrderProps | undefined>();
  const [searchProducts, setSearchProducts] = useState<
    ProductSearchProps[] | undefined
  >([]);
  const [properties, setProperties] = useState<Property[] | undefined>([]);

  useEffect(() => {
    console.log(product);
  }, [product]);

  return (
    <ProductContext.Provider
      value={{
        newProduct,
        setNewProduct,
        product,
        setProduct,
        searchProducts,
        setSearchProducts,
        properties,
        setProperties,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductProvider };
