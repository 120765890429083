import { AutoComplete, Button, Empty, Select, Tooltip } from "antd";

import "./CompanyPart.css";
import { useEffect, useState } from "react";
import axios_instance from "../../../../requests/api";
import useDisplay from "../../../../hooks/useDisplay";
import useCompany from "../../../../hooks/useCompany";
import useContact from "../../../../hooks/useContact";
import useWarehouse from "../../../../hooks/useWarehouse";
import useProduct from "../../../../hooks/useProduct";
import { Contact } from "../../../../types/contact";
import { Company } from "../../../../types/company";

const CopmanyPart: React.FC = () => {
  const { displayStep, setDisplayStep } = useDisplay();
  const {
    company,
    setCompany,
    companies,
    setCompanies,
    newCompany,
    setNewCompany,
  } = useCompany();
  const {
    contact,
    setContact,
    contacts,
    setContacts,
    newContact,
    setNewContact,
  } = useContact();
  const { setProduct } = useProduct();
  const { setWarehouse } = useWarehouse();
  const [query, setQuery] = useState("");
  const [taxNumError, setTaxNumError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [companiesLength, setCompaniesLenght] = useState<number | null>(null);
  const [contactsLength, setContactsLength] = useState<number | null>(null);
  type CompanyKeys = keyof Company;
  type ContactKeys = keyof Contact;

  const handleCompanyChange = (type: CompanyKeys, value: any) => {
    const company: any = companies?.find((company) => company[type] === value);
    setCompany(company);
  };

  const handleContactChange = (type: ContactKeys, value: any) => {
    const contact: any = contacts?.find((contact) => contact[type] === value);
    setContact(contact);
    setDisplayStep(2);
  };

  const handleInputChange = (field: string, state: string, value: any) => {
    if (state === "company") {
      if (field === "tax_number") {
        setTaxNumError(false);
      }
      // @ts-ignore
      setCompany((prevValue: any) => ({
        ...prevValue,
        [field]: value,
      }));
    } else if (state === "contact") {
      if (field === "email") {
        setEmailError(false);
      }
      if (field === "phone_number") {
        setPhoneError(false);
      }
      // @ts-ignore
      setContact((prevValue: any) => ({
        ...prevValue,
        [field]: value,
      }));
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone: string): boolean => {
    const phoneRegex = /^\+7(9|8)\d{9}$/;
    return phoneRegex.test(phone);
  };

  async function getCompanies() {
    let result;
    await axios_instance
      .get("/myLegalEntities?query=" + query)
      .then((response: any) => {
        setCompanies(response.data.results);
        if (companiesLength === null) {
          setCompaniesLenght(response.data.results.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  }

  async function createEntity() {
    if (
      company?.tax_number.length === 10 &&
      /^[0-9]+$/.test(company?.tax_number)
    ) {
      let result;
      await axios_instance
        .post("/entity/create", {
          name: company?.name,
          tax_number: company?.tax_number,
        })
        .then((response: any) => {
          setNewCompany(false);
          setCompany(response.data);
          setContactsLength(response.data.contacts.length);
        })
        .catch((error) => {
          console.log(error);
        });
      return result;
    } else {
      setTaxNumError(true);
    }
  }

  async function createContact() {
    if (
      contact &&
      company?.id &&
      validateEmail(contact?.email) &&
      validatePhoneNumber(contact?.phone_number)
    ) {
      let result;
      await axios_instance
        .post(`/entity/${company?.id}/contact/create`, {
          first_name: contact?.first_name,
          email: contact?.email,
          last_name: contact?.last_name,
          phone_number: contact?.phone_number,
          middle_name: contact?.middle_name,
        })
        .then((response: any) => {
          setNewContact(false);
          setContact(response.data);
          getCompanies();
          setDisplayStep(2);
        })
        .catch((error) => {
          console.log(error);
        });
      return result;
    } else {
      if (contact && !validateEmail(contact?.email)) {
        setEmailError(true);
      }
      if (contact && !validatePhoneNumber(contact?.phone_number)) {
        setPhoneError(true);
      }
    }
  }

  const companyColumns = [
    {
      title: "Наименование компаний",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ИНН",
      dataIndex: "tax_number",
      key: "tax_number",
    },
  ];

  const contactColumns = [
    {
      title: "Имя",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Отчество",
      dataIndex: "middle_name",
      key: "middle_name",
    },
    {
      title: "Фамилия",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Телефон",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  useEffect(() => {
    if (displayStep === 0) {
      setCompany(undefined);
      setContact(undefined);
      setQuery("");
    }
  }, [displayStep]);

  useEffect(() => {
    company && setContacts(company?.contacts);
    company && setContactsLength(company?.contacts?.length);
  }, [company]);

  useEffect(() => {
    getCompanies();
  }, [query]);

  return (
    <div className="part-main">
      <div className="part-content-info">
        <span className="circle-block">1</span>
        <span className="part-header-text">Информация о покупателе</span>
      </div>
      <div className="part-content-main">
        {!company?.id && !newCompany ? (
          <Empty
            style={{ width: "100%" }}
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={
              <span className="emty-description-text">
                {companies && companiesLength && companiesLength > 0
                  ? "Выберите или добавьте новую компанию от которой хотите оформить доставку"
                  : "Добавьте новую компанию"}
              </span>
            }
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              {companies && companiesLength && companiesLength > 0 && (
                <Select
                  onSearch={(value: string) => setQuery(value)}
                  onChange={(value: string) => handleCompanyChange("id", value)}
                  showSearch
                  placeholder="Поиск по ИНН/Наименованию компании"
                  style={{ width: "100%" }}
                  filterOption={() => true}
                  notFoundContent="Нет совпадений"
                >
                  {companies?.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      <span>
                        Наименование: <strong>{company.name}</strong>, ИНН:{" "}
                        <strong>{company.tax_number}</strong>
                      </span>
                    </Select.Option>
                  ))}
                </Select>
              )}
              <Button
                color="danger"
                variant="solid"
                onClick={() => setNewCompany(true)}
                style={{ width: "100%" }}
              >
                Добавить компанию
              </Button>
            </div>
          </Empty>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                color="danger"
                variant="solid"
                onClick={() => {
                  setQuery("");
                  setTaxNumError(false);
                  setDisplayStep(1);
                  setWarehouse(undefined);
                  setProduct(undefined);
                  if (newContact || contact) {
                    setNewContact(false);
                    setContact(undefined);
                  } else {
                    setNewCompany(false);
                    setCompany(undefined);
                    setContacts([]);
                  }
                }}
              >
                Назад
              </Button>
            </div>
            <div className="part-content-cell">
              {companyColumns.map((cell) => {
                type CompanyKeysExcludingContacts = Exclude<
                  keyof Company,
                  keyof Contact
                >;
                const x: CompanyKeysExcludingContacts =
                  cell.key as CompanyKeysExcludingContacts;
                return (
                  <div className="cell">
                    <span style={{ opacity: "50%" }}>{cell.title}</span>
                    <Tooltip
                      title={
                        x === "tax_number" && taxNumError
                          ? "ИНН должен состоять из 10 цифр"
                          : ""
                      }
                    >
                      <AutoComplete
                        disabled={!newCompany}
                        value={company && company[x]}
                        status={
                          x === "tax_number" && taxNumError ? "error" : ""
                        }
                        onSearch={(value: any) => {
                          setQuery(value);
                          handleInputChange(cell.key, "company", value);
                        }}
                        onSelect={(value: any) => {
                          setQuery("");
                          setNewCompany(false);
                          handleCompanyChange(x, value);
                        }}
                        placeholder={cell.title}
                        options={companies?.map((company) => ({
                          value: company[x],
                          label: company[x],
                        }))}
                        filterOption={() => true}
                        style={{ width: "100%" }}
                      />
                    </Tooltip>
                  </div>
                );
              })}
              {newCompany ? (
                <Button
                  color="danger"
                  variant="solid"
                  disabled={
                    company?.name === undefined ||
                    company.name === "" ||
                    company.tax_number === undefined ||
                    company.tax_number === ""
                  }
                  onClick={() => createEntity()}
                  style={{ width: "100%" }}
                >
                  Сохранить компанию
                </Button>
              ) : !newContact && !contact ? (
                <div className="part-content-cell">
                  {contacts && contactsLength && contactsLength > 0 ? (
                    <Select
                      onSelect={(value: any) =>
                        handleContactChange("id", value)
                      }
                      placeholder="Выбрать контакт"
                      options={contacts?.map((contact) => ({
                        value: contact.id,
                        label: `${contact.last_name} ${contact.first_name}`,
                      }))}
                      style={{ width: "100%" }}
                    />
                  ) : null}
                  <Button
                    color="danger"
                    variant="solid"
                    onClick={() => setNewContact(true)}
                    style={{ width: "100%" }}
                  >
                    Добавить новое контактное лицо
                  </Button>
                </div>
              ) : (
                <>
                  {contactColumns.map((cell) => {
                    const x: ContactKeys = cell.key as ContactKeys;
                    return (
                      <div className="cell">
                        <span style={{ opacity: "50%" }}>{cell.title}</span>
                        <Tooltip
                          title={
                            (x === "email" && emailError) ||
                            (x === "phone_number" && phoneError)
                              ? x === "email" && emailError
                                ? "Почта должна быть в формате: email@example.com"
                                : "Номер телефона начинаться с +79 или +78 и состоять из 11 цифр"
                              : ""
                          }
                        >
                          <AutoComplete
                            disabled={!newContact}
                            value={contact && contact[x]}
                            status={
                              (x === "email" && emailError) ||
                              (x === "phone_number" && phoneError)
                                ? "error"
                                : ""
                            }
                            onSearch={(value: any) =>
                              handleInputChange(x, "contact", value)
                            }
                            onSelect={(value: any) =>
                              handleInputChange(x, "contact", value)
                            }
                            placeholder={cell.title}
                            style={{ width: "100%" }}
                            options={contacts?.map((contact) => ({
                              value: contact[x],
                              label: contact[x],
                            }))}
                          />
                        </Tooltip>
                      </div>
                    );
                  })}
                  {newContact && (
                    <div className="part-content-cell">
                      <Button
                        color="danger"
                        variant="solid"
                        disabled={
                          contact?.last_name === undefined ||
                          contact.last_name === "" ||
                          contact.first_name === undefined ||
                          contact.first_name === "" ||
                          contact.email === undefined ||
                          contact.email === "" ||
                          contact.middle_name === undefined ||
                          contact.middle_name === "" ||
                          contact.phone_number === undefined ||
                          contact.phone_number === ""
                        }
                        onClick={() => createContact()}
                        style={{ width: "100%" }}
                      >
                        Сохранить
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CopmanyPart;
