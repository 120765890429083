import { useContext } from "react";
import { DisplayContext } from "../context/displayContext";

const useDisplay = () => {
  const context = useContext(DisplayContext);
  if (!context) {
    throw new Error("useDisplay must be used within a DisplayProvider");
  }
  return context;
};

export default useDisplay;
