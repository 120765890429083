import ProductPart from "./productPart/ProductPart";
import WarehousePart from "./warehousePart/WarehousePart";

import "./CalcContainer.css";
import ResultPart from "./resultPart/ResultPart";
import { Tabs } from "antd";
import { useEffect, useRef } from "react";
import OrderPart from "./orderPart/OrderPart";
import Tracking from "./tracking/Tracking";
import CopmanyPart from "./companyPart/CompanyPart";
import useDisplay from "../../../hooks/useDisplay";
import useOrder from "../../../hooks/useOrder";
import OrderCreatedPart from "./orderCreatedPart/OrderCreatedPart";

const CalcContainer = () => {
  const resultRef = useRef<HTMLDivElement>(null);
  const orderRef = useRef<HTMLDivElement>(null);
  const { displayStep } = useDisplay();
  const { orderCreated } = useOrder();

  const tabs = [
    {
      label: <span className="tab-keys-text">Рассчитать доставку</span>,
      key: "1",
      children: (
        <div className="calc-container-content">
          {!orderCreated?.delivered ? (
            <>
              <CopmanyPart />
              <WarehousePart />
              <ProductPart />
              <ResultPart ref={resultRef} />
              <OrderPart ref={orderRef} />
            </>
          ) : (
            <OrderCreatedPart />
          )}
        </div>
      ),
    },
    {
      label: (
        <span className="tab-keys-text">Проверить доставку по номеру</span>
      ),
      key: "2",
      children: <Tracking />,
    },
  ];

  useEffect(() => {
    if (displayStep === 4 && resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (displayStep === 5 && orderRef.current) {
      orderRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayStep]);

  return (
    <div className="calc-container-main">
      <Tabs defaultActiveKey="1" items={tabs} style={{ width: "100%" }} />
    </div>
  );
};

export default CalcContainer;
