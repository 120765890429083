import React, { createContext, useState, ReactNode, useEffect } from "react";

interface DisplayContextType {
  displayStep: number;
  setDisplayStep: (step: number) => void;
}

export const DisplayContext = createContext<DisplayContextType | undefined>(
  undefined
);

const DisplayProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [displayStep, setDisplayStep] = useState<number>(1);

  useEffect(() => {
    console.log(displayStep);
  }, [displayStep]);

  return (
    <DisplayContext.Provider value={{ displayStep, setDisplayStep }}>
      {children}
    </DisplayContext.Provider>
  );
};

export { DisplayProvider };
