import { Button, Select, AutoComplete, Empty, InputNumber } from "antd";

import "./ProductPart.css";
import { useEffect, useRef, useState } from "react";
import axios_instance from "../../../../requests/api";
import useDisplay from "../../../../hooks/useDisplay";
import useProduct from "../../../../hooks/useProduct";
import useWarehouse from "../../../../hooks/useWarehouse";
import useContact from "../../../../hooks/useContact";
import useCompany from "../../../../hooks/useCompany";
import useOrder from "../../../../hooks/useOrder";
import { BrandAndManufacture, Property } from "../../../../types/product";

const ProductPart: React.FC = () => {
  const {
    newProduct,
    setNewProduct,
    searchProducts,
    setSearchProducts,
    product,
    setProduct,
  } = useProduct();
  const { displayStep, setDisplayStep } = useDisplay();
  const { warehouse } = useWarehouse();
  const { contact } = useContact();
  const { company } = useCompany();
  const { setOrder } = useOrder();

  const [cost, setCost] = useState(0);

  const [query, setQuery] = useState("");
  const [brands, setBrands] = useState<BrandAndManufacture[]>([]);
  const [brandQuery, setBrandQuery] = useState("");
  const [manufactures, setManufactures] = useState<BrandAndManufacture[]>([]);
  const [manufactureQuery, setManufactureQuery] = useState("");
  const websocket = useRef<WebSocket | null>(null);
  const queryRef = useRef(query);

  useEffect(() => {
    websocket.current = new WebSocket(
      "wss://dev-ib-elp.psg.su/ws/product/searcher/"
    );
    websocket.current.onopen = () => {
      console.log("WebSocket is open now.");
    };

    websocket.current.onclose = () => {
      console.log("WebSocket is closed now.");
    };

    websocket.current.onmessage = (event) => {
      console.log("WebSocket message received:", event);
      let data = JSON.parse(event.data);
      setSearchProducts(data.data);
    };

    websocket.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      websocket.current?.close();
    };
  }, []);

  const sendMessage = () => {
    if (websocket && websocket.current?.readyState === WebSocket.OPEN) {
      if (query) {
        let request_id: any = new Date().getTime();
        queryRef.current = request_id;
        const message = {
          request_id: request_id,
          action: "search",
          code: query,
          entity: "",
        };
        websocket.current.send(JSON.stringify(message));
      }
    }
  };

  async function getBrands() {
    await axios_instance
      .get(`/brand_list?query=${brandQuery}`)
      .then((response: any) => {
        setBrands(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getManufactures() {
    await axios_instance
      .get(`/manufacture_list?query=${manufactureQuery}`)
      .then((response: any) => {
        setManufactures(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getProductById = async (_id: string) => {
    await axios_instance
      .get(`/product/by_id/${_id}`)
      .then(({ data }) => {
        data.properties.map((property: Property) => {
          if (property.name === "Вес") {
            handleInputChange("weight", property.value.content);
          } else if (property.name === "Длина") {
            handleInputChange("lenght", property.value.content);
          } else if (property.name === "Ширина") {
            handleInputChange("width", property.value.content);
          } else if (property.name === "Высота") {
            handleInputChange("height", property.value.content);
          }
        });
      })
      .catch((error) => {});
  };

  async function calculate() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    await axios_instance
      .post(`/delivery_plus/calculate`, {
        product_id: product?.id,
        product_article: product?.code,
        product_brand: product?.brand,
        product_manufacture: product?.manufacture,
        product_name: product?.name,
        weight: product?.weight,
        lenght: product?.lenght,
        width: product?.width,
        height: product?.height,
        count: product?.count,
        cost: product?.price,
        contractor_warehouse: warehouse?.id,
        contact: contact?.id,
        contractor: company?.id,
        order_name: `${product?.code} - ${formattedDate}`,
      })
      .then((response: any) => {
        setOrder(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleProductChange = (value: any) => {
    const product: any = searchProducts?.find(
      (product) => product.id === value
    );
    setProduct(product);
    getProductById(product.id);
    getBrands();
    getManufactures();
  };

  const handleInputChange = (field: string, value: any) => {
    // @ts-ignore
    setProduct((prevProduct: any) => ({
      ...prevProduct,
      [field]: value,
    }));
  };

  useEffect(() => {
    getBrands();
  }, [brandQuery]);

  useEffect(() => {
    getManufactures();
  }, [manufactureQuery]);

  useEffect(() => {
    sendMessage();
  }, [query]);

  useEffect(() => {
    if (product?.price && product?.count) {
      setCost(product?.price * product?.count);
    }
  }, [product?.price, product?.count]);

  useEffect(() => {
    if (newProduct || product?.id) {
      // @ts-ignore
      setProduct((prevProduct: any) => ({
        ...prevProduct,
        price: 1,
        count: 1,
      }));
      if (newProduct) {
        // @ts-ignore
        setProduct((prevProduct: any) => ({
          ...prevProduct,
          height: 1,
          weight: 1,
          width: 1,
          lenght: 1,
        }));
      }
    }
  }, [product?.id, newProduct]);

  if (displayStep < 3) return null;

  return (
    <div className="part-main">
      <div className="part-content-info">
        <span className="circle-block">3</span>
        <span className="part-header-text">Иноформация о товаре</span>
      </div>
      <div className="part-content-main">
        {!product?.id && !newProduct ? (
          <Empty
            style={{ width: "100%" }}
            imageStyle={{ height: 50 }}
            description={
              <span className="emty-description-text">
                Выберите существующий или добавьте новый товар в нашу базу
                данных
              </span>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%",
              }}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Поиск по артикулу/наименованию/бренду/производителю"
                filterOption={() => true}
                onSelect={handleProductChange}
                onSearch={(value) => setQuery(value)}
                notFoundContent="Нет совпадений"
              >
                {searchProducts?.map((product) => (
                  <Select.Option key={product.id} value={product.id}>
                    <span style={{ overflowX: "scroll" }}>
                      Наименование: <strong>{product.name}</strong>, Артикул:{" "}
                      <strong>{product.code}</strong>, Бренд:{" "}
                      <strong>{product.brand}</strong>, Производитель:
                      <strong>{product.manufacture}</strong>
                    </span>
                  </Select.Option>
                ))}
              </Select>
              <Button
                style={{ width: "100%" }}
                color="danger"
                variant="solid"
                onClick={() => setNewProduct(true)}
              >
                Новый товар
              </Button>
            </div>
          </Empty>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                color="danger"
                variant="solid"
                onClick={() => {
                  setNewProduct(false);
                  setProduct(undefined);
                  setCost(0);
                  setBrandQuery("");
                  setManufactureQuery("");
                  setDisplayStep(3);
                }}
              >
                Назад
              </Button>
            </div>

            <div className="part-content-cell">
              <div className="cell">
                <span style={{ opacity: "50%" }}>Артикул</span>
                <AutoComplete
                  disabled={!newProduct}
                  value={product?.code}
                  onSearch={(value: string) => handleInputChange("code", value)}
                  placeholder="Артикул"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Наименование</span>
                <AutoComplete
                  disabled={!newProduct}
                  value={product?.name}
                  onSearch={(value: string) => handleInputChange("name", value)}
                  placeholder="Наименование"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Бренд</span>
                <AutoComplete
                  disabled={!newProduct}
                  value={product?.brand}
                  onSelect={(value: string) =>
                    handleInputChange("brand", value)
                  }
                  onSearch={(value: string) => {
                    setBrandQuery(value);
                    handleInputChange("brand", value);
                  }}
                  options={brands?.map((brand) => ({
                    value: brand.name,
                    label: brand.name,
                  }))}
                  placeholder="Бренд"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Производитель</span>
                <AutoComplete
                  disabled={!newProduct}
                  value={product?.manufacture}
                  onSelect={(value: string) =>
                    handleInputChange("manufacture", value)
                  }
                  onSearch={(value: string) => {
                    setManufactureQuery(value);
                    handleInputChange("manufacture", value);
                  }}
                  options={manufactures?.map((manufacture) => ({
                    value: manufacture.name,
                    label: manufacture.name,
                  }))}
                  placeholder="Производитель"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="part-content-cell">
              <div className="cell">
                <span style={{ opacity: "50%" }}>Высота, см</span>
                <InputNumber
                  disabled={!newProduct}
                  value={product?.height}
                  style={{ width: "100%" }}
                  min={0}
                  onChange={(value: number | null) => {
                    if (value !== null) {
                      handleInputChange("height", value);
                    }
                  }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Ширина, см</span>
                <InputNumber
                  value={product?.width}
                  disabled={!newProduct}
                  style={{ width: "100%" }}
                  min={0}
                  onChange={(value: number | null) => {
                    if (value !== null) {
                      handleInputChange("width", value);
                    }
                  }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Длина, см</span>
                <InputNumber
                  value={product?.lenght}
                  disabled={!newProduct}
                  style={{ width: "100%" }}
                  min={0}
                  onChange={(value: number | null) => {
                    if (value !== null) {
                      handleInputChange("lenght", value);
                    }
                  }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Вес, кг</span>
                <InputNumber
                  value={product?.weight}
                  disabled={!newProduct}
                  style={{ width: "100%" }}
                  min={0}
                  onChange={(value: number | null) => {
                    if (value !== null) {
                      handleInputChange("weight", value);
                    }
                  }}
                />
              </div>
            </div>

            <div className="part-content-cell">
              <div className="cell">
                <span style={{ opacity: "50%" }}>Количество</span>
                <InputNumber
                  value={product?.count}
                  style={{ width: "100%" }}
                  min={0}
                  onChange={(value: number | null) => {
                    if (value !== null) {
                      handleInputChange("count", value);
                    }
                  }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Цена, руб</span>
                <InputNumber
                  value={product?.price}
                  style={{ width: "100%" }}
                  min={0}
                  onChange={(value: number | null) => {
                    if (value !== null) {
                      handleInputChange("price", value);
                    }
                  }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Стоимость, руб</span>
                <InputNumber disabled style={{ width: "100%" }} value={cost} />
              </div>
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "end",
                  }}
                >
                  <Button
                    disabled={
                      ((product?.id !== undefined ||
                        product?.count === undefined ||
                        product?.price === undefined) &&
                        product?.code === undefined) ||
                      product?.weight === undefined ||
                      product?.height === undefined ||
                      product?.lenght === undefined ||
                      product?.count === undefined ||
                      product?.count === 0 ||
                      product?.price === 0 ||
                      product?.price === undefined ||
                      product?.code === undefined
                    }
                    style={{
                      width: "100%",
                    }}
                    color="danger"
                    variant="solid"
                    onClick={() => {
                      calculate();
                      setDisplayStep(4);
                    }}
                  >
                    Рассчитать
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductPart;
