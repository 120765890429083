import { Button } from "antd";
import { logOut } from "../../requests/authorization";

import "./Header.css";

interface HeaderProps {
  setIsAuth: (isAuth: boolean) => void;
  isAuth: boolean;
}

const Header: React.FC<HeaderProps> = ({ setIsAuth, isAuth }) => {
  return (
    <div className="header-main">
      <div className="header-content">
        <img src="/psg_logo.svg" alt="PSG_LOGO" />
        {isAuth && (
          <Button
            style={{ width: "fit-content" }}
            color="danger"
            variant="solid"
            onClick={() => {
              const res: any = logOut();
              setIsAuth(!res);
            }}
          >
            Сменить пользователя
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
