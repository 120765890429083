import { Button, Table } from "antd";

import "./ResultPart.css";
import { forwardRef, useEffect, useState } from "react";
import useDisplay from "../../../../hooks/useDisplay";
import useOrder from "../../../../hooks/useOrder";
import useProduct from "../../../../hooks/useProduct";
import axios_instance from "../../../../requests/api";

const ResultPart = forwardRef<HTMLDivElement, any>((props, ref) => {
  const { displayStep, setDisplayStep } = useDisplay();
  const [pollingAttempts, setPollingAttempts] = useState<number>(0);
  const {
    order,
    setOrder,
    selectedRowKey,
    setSelectedRowKey,
    setOrderCreated,
  } = useOrder();
  const { product } = useProduct();
  const [manager, setManager] = useState("");
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Тарифы",
      dataIndex: "delivery_type",
      key: "delivery_type",
      align: "center" as "center",
    },
    {
      title: "Сроки доставки (дн)",
      render: (text: string, record: any) =>
        `${record.delivery_min_period} - ${record.delivery_max_period}`,
      key: "delivery_period",
      align: "center" as "center",
    },
    {
      title: "Стоимость товара с доставкой (руб)",
      dataIndex: "order_price",
      key: "order_price",
      align: "center" as "center",
    },
  ];

  const onSelectChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKey(selectedKeys[0]);
    setDisplayStep(5);
    setChoosenVariant(selectedKeys[0]);
  };

  const rowSelection = {
    type: "radio" as "radio",
    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
    onChange: onSelectChange,
  };

  async function getOrderProject(retry = false) {
    setLoading(true);
    await axios_instance
      .get(`/orders/order_project/managers/get/by_id/${order?.id}`)
      .then((response: any) => {
        if (response.data.managers?.[0]?.id) {
          // @ts-ignore
          setOrderCreated((prevState: any) => ({
            ...prevState,
            name: response.data.name,
            number: response.data.number,
          }));
          startLongPolling(response.data.managers[0].id);
          setManager(response.data.managers[0].id);
        } else if (!retry) {
          // Если нет id и повторный запрос ещё не выполнен, делаем повторный запрос
          getOrderProject(true);
        } else {
          console.log("ID не найден после повторного запроса.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function setChoosenVariant(chosen_variant: React.Key) {
    await axios_instance
      // @ts-ignore
      .put(`/orders/order_manager/choose/variant/${manager}`, {
        chosen_variant: chosen_variant,
      })
      .then((response: any) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  let pollingInterval: ReturnType<typeof setInterval> | null = null;
  const maxPollingAttempts = 5;

  async function longPolling(_id: string) {
    try {
      const response = await axios_instance.get(
        `/orders/order_manager/get/by_id/${_id}`
      );
      if (response.data.manager_dict.length > 0) {
        stopLongPolling();
        // @ts-ignore
        setOrder((prevState: any) => ({
          ...prevState,
          managers: [...(prevState.managers || []), response.data],
        }));
        setLoading(false);
      } else {
        pollingAttempts && setPollingAttempts(pollingAttempts + 1);
        if (pollingAttempts && pollingAttempts >= maxPollingAttempts) {
          stopLongPolling();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function startLongPolling(_id: string) {
    pollingInterval = setInterval(() => longPolling(_id), 5000);
  }

  function stopLongPolling() {
    if (pollingInterval) {
      clearInterval(pollingInterval);
      pollingInterval = null;
    }
  }

  useEffect(() => {
    if (product === undefined) {
      setOrder(undefined);
      setLoading(true);
      setSelectedRowKey(undefined);
    }
  }, [product]);

  useEffect(() => {
    if (displayStep === 4) {
      setPollingAttempts(0);
      getOrderProject();
    }
  }, [displayStep, order?.id]);

  if (displayStep < 4) return null;

  return (
    <div className="part-main" ref={ref}>
      <div className="part-content-info">
        <span className="circle-block">4</span>
        <span className="part-header-text">Расчеты доставки</span>
      </div>
      <div className="part-content-main">
        <div className="result-content">
          <Table
            style={{ width: "100%" }}
            rowSelection={rowSelection}
            dataSource={
              order?.managers?.[0]?.manager_dict?.map((item: any) => ({
                ...item,
                key: item.id,
              })) || []
            }
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
});

export default ResultPart;
