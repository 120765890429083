import React, { createContext, useState, ReactNode } from "react";
import { Warehouse } from "../types/warehouse";

interface WarehouseContextType {
  setWarehouse: (warehouse: Warehouse | undefined) => void;
  warehouse: Warehouse | undefined;
  setWarehouses: (warehouses: Warehouse[] | undefined) => void;
  warehouses: Warehouse[] | undefined;
  setNewWarehouse: (warehouse: boolean) => void;
  newWarehouse: boolean;
}

export const WarehouseContext = createContext<WarehouseContextType | undefined>(
  undefined
);

const WarehouseProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [warehouse, setWarehouse] = useState<Warehouse | undefined>();
  const [warehouses, setWarehouses] = useState<Warehouse[] | undefined>([]);
  const [newWarehouse, setNewWarehouse] = useState(false);

  return (
    <WarehouseContext.Provider
      value={{
        setNewWarehouse,
        newWarehouse,
        setWarehouses,
        warehouses,
        setWarehouse,
        warehouse,
      }}
    >
      {children}
    </WarehouseContext.Provider>
  );
};

export { WarehouseProvider };
