import { AutoComplete, Button, Empty, Select } from "antd";
import { BankOutlined } from "@ant-design/icons";

import "./WarehousePart.css";
import { useEffect, useState } from "react";
import axios_instance from "../../../../requests/api";
import useDisplay from "../../../../hooks/useDisplay";
import { GeoObject } from "../../../../types/warehouse";
import useWarehouse from "../../../../hooks/useWarehouse";
import useCompany from "../../../../hooks/useCompany";
import useProduct from "../../../../hooks/useProduct";

const WarehousePart: React.FC = () => {
  const {
    setNewWarehouse,
    newWarehouse,
    warehouse,
    setWarehouse,
    warehouses,
    setWarehouses,
  } = useWarehouse();
  const { company } = useCompany();
  const { setProduct } = useProduct();
  const [warehousesLength, setWarehousesLenght] = useState<number | null>(null);
  const { displayStep, setDisplayStep } = useDisplay();
  const [query, setQuery] = useState("");
  const [q, setQ] = useState("");
  const [geo_objects, setGeoObjects] = useState<GeoObject[]>([]);

  async function getWarehouses() {
    await axios_instance
      .get(`/myCompany/addresses/${company?.id}?query=${query}`)
      .then((response: any) => {
        setWarehouses(response.data);
        if (warehousesLength === null) {
          setWarehousesLenght(response.data.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function createAddress() {
    if (
      warehouse &&
      warehouse.geo_object_full &&
      warehouse.exact_address &&
      warehouse.name
    ) {
      let result;
      await axios_instance
        .post(`/address/create`, {
          name: warehouse?.name,
          geo_object: warehouse?.geo_object_full?.id,
          exact_address: warehouse?.exact_address,
          entity: company?.id,
        })
        .then((response: any) => {
          setNewWarehouse(false);
          setWarehouse(response.data);
          getWarehouses();
          setDisplayStep(3);
        })
        .catch((error) => {
          console.log(error);
        });
      return result;
    }
  }

  const geoguidSearch = async () => {
    await axios_instance
      .get(`/geo_object/list?contentType=city&q=${q}`)
      .then(({ data }) => {
        setGeoObjects(data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (value: any) => {
    const warehouse: any = warehouses?.find(
      (warehouse) => warehouse.id === value
    );
    setWarehouse(warehouse);
    setDisplayStep(3);
  };

  const handleGeoObjectIdChange = (newGeoObjectId: string) => {
    // @ts-ignore
    setWarehouse((prevWarehouse: any) => ({
      ...prevWarehouse,
      geo_object_full: {
        ...prevWarehouse?.geo_object_full,
        id: newGeoObjectId,
      },
    }));
  };

  const handleInputChange = (field: string, value: any) => {
    // @ts-ignore
    setWarehouse((prevSender) => ({
      ...prevSender,
      [field]: value,
    }));
  };

  useEffect(() => {
    geoguidSearch();
  }, [q]);

  useEffect(() => {
    if (company === undefined) {
      setWarehouses([]);
      setWarehousesLenght(null);
      setWarehouse(undefined);
    } else {
      getWarehouses();
    }
  }, [query, company]);

  if (displayStep < 2) return null;

  return (
    <div className="part-main">
      <div className="part-content-info">
        <span className="circle-block">2</span>
        <span className="part-header-text">Маршрут доставки</span>
      </div>
      <div className="part-content-main">
        {!warehouse?.id && !newWarehouse ? (
          <Empty
            style={{ width: "100%" }}
            image={
              <BankOutlined style={{ fontSize: 50, color: "#00000040" }} />
            }
            imageStyle={{ height: 60 }}
            description={
              <span className="emty-description-text">
                {warehouses && warehousesLength && warehousesLength > 0
                  ? "Выберите склад компании или создайте новый"
                  : "Добавьте новый склад"}
              </span>
            }
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              {warehouses && warehousesLength && warehousesLength > 0 ? (
                <Select
                  optionFilterProp="label"
                  onSearch={(value: string) => setQuery(value)}
                  onSelect={(value: string) => handleChange(value)}
                  showSearch
                  placeholder="Поиск по наименованию"
                  style={{ width: "100%" }}
                  options={warehouses?.map((cell) => ({
                    value: cell.id,
                    label: cell.name,
                  }))}
                  filterOption={() => true}
                  notFoundContent="Нет совпадений"
                />
              ) : null}
              <Button
                color="danger"
                variant="solid"
                onClick={() => setNewWarehouse(true)}
                style={{ width: "100%" }}
              >
                Добавить склад
              </Button>
            </div>
          </Empty>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                color="danger"
                variant="solid"
                onClick={() => {
                  setNewWarehouse(false);
                  setWarehouse(undefined);
                  setProduct(undefined);
                  setDisplayStep(2);
                }}
              >
                Назад
              </Button>
            </div>
            <div className="part-content-cell">
              <div className="cell">
                <span style={{ opacity: "50%" }}>Название</span>
                <AutoComplete
                  disabled={!newWarehouse}
                  value={warehouse?.name}
                  onSearch={(value: string) => handleInputChange("name", value)}
                  placeholder="Название"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Населенный пункт</span>
                <Select
                  showSearch
                  value={warehouse?.geo_object_full?.full_name}
                  filterOption={() => true}
                  disabled={!newWarehouse}
                  onSearch={(value: string) => setQ(value)}
                  onChange={(value: string) => handleGeoObjectIdChange(value)}
                  placeholder="Населенный пункт"
                  style={{ width: "100%" }}
                  options={geo_objects?.map((geo) => ({
                    value: geo.id,
                    label: geo.full_name,
                  }))}
                />
              </div>
              <div className="cell">
                <span style={{ opacity: "50%" }}>Точный адрес</span>
                <AutoComplete
                  disabled={!newWarehouse}
                  value={warehouse?.exact_address}
                  onSearch={(value: string) =>
                    handleInputChange("exact_address", value)
                  }
                  placeholder="Точный адресс"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {newWarehouse && (
              <div className="part-content-cell">
                <Button
                  disabled={
                    warehouse?.name === "" ||
                    warehouse?.geo_object_full?.id === "" ||
                    warehouse?.exact_address === "" ||
                    warehouse?.name === undefined ||
                    warehouse?.geo_object_full?.id === undefined ||
                    warehouse?.exact_address === undefined
                  }
                  style={{ width: "100%" }}
                  color="danger"
                  variant="solid"
                  onClick={() => {
                    createAddress();
                  }}
                >
                  Сохранить склад
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WarehousePart;
