import React, { createContext, useState, ReactNode, useEffect } from "react";
import { Company } from "../types/company";

interface CompanyContextType {
  company: Company | undefined;
  setCompany: (company: Company | undefined) => void;
  companies: Company[] | undefined;
  setCompanies: (companies: Company[] | undefined) => void;
  newCompany: boolean;
  setNewCompany: (newCompany: boolean) => void;
}

export const CompanyContext = createContext<CompanyContextType | undefined>(
  undefined
);

const CompanyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [company, setCompany] = useState<Company | undefined>();
  const [companies, setCompanies] = useState<Company[] | undefined>([]);
  const [newCompany, setNewCompany] = useState(false);

  useEffect(() => {
    console.log(company);
  }, [company]);

  return (
    <CompanyContext.Provider
      value={{
        company,
        setCompany,
        companies,
        setCompanies,
        newCompany,
        setNewCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyProvider };
